<template>
  <BaseLayout>
    <Offers />
  </BaseLayout>
</template>

<script>
import BaseLayout from '../../layouts/BaseLayout'
import Offers from '@/components/Offers/Index'
export default {
  name: 'OffersPage',
  components: { Offers, BaseLayout }
}
</script>

<style scoped></style>
