<template>
  <div class="offers-page">
    <BasePageTitle
      :title="title ? title : 'offers.browseOffers'"
      bg-color-class="main-color-bg-with-opacity"
      color-class="main-color-text"
      svg-color-class="main-color-fill-svg"
      :links="links"
    />
    <router-view />
  </div>
</template>

<script>
import BasePageTitle from '@/components/partials/base-page-title/Index.vue'
// import { EventBus } from '../../utils/event-bus'

export default {
  name: 'Offers',
  components: { BasePageTitle },
  props: {},
  data () {
    return {
      title: '',
      links: [
        {
          name: 'offers.newOffers',
          routerName: 'new-offers'
        },
        {
          name: 'offers.bestOffers',
          routerName: 'best-offers'
        }
      ]
    }
  },

  watch: {
    '$route.query': {
      handler () {
        this.title = this.$route.query.categoryName ? this.$route.query.categoryName : ''
      },
      immediate: true
    }
  },
  mounted () {
    this.title = this.$route.query.categoryName ? this.$route.query.categoryName : ''

    // this.ChangeTitlePage()
  },

  beforeUnmount () {
    // EventBus.$off('ChangeTitlePage')
  },

  methods: {
    // ChangeTitlePage () {
    //   EventBus.$on('ChangeTitlePage', (data) => {
    //     this.title = data.name
    //   })
    // }
  }
}
</script>

<style scoped></style>
